import * as React from "react";
import styled from "styled-components";
import Container from "../components/common/Container";

const PrivacyStyled = styled.div`
  padding: 24px;
`;
const Privacy = () => {
  return (
    <Container>
      <PrivacyStyled>
        <h1>POLITIKA PRIVATNOSTI</h1>
        <p>Verzija: 1.0</p>
        <p>Datum: 01.06.2022. godine</p>
        <h2>O OVOJ POLITICI PRIVATNOSTI</h2>
        <p>
          Ova Politika privatnosti opisuje naše politike i procedure o
          prikupljanju, korišćenju i otkrivanju vaših ličnih podataka, kada
          koristite našu Uslugu i obavještava vas o vašem pravu na privatnost i
          o tome kako vas zakon štiti.
        </p>
        <p>
          Koristimo vaše lične podatke u svrhu pružanja i poboljšavanja naše
          Usluge. Koristeći našu Uslugu, pristajete na prikupljanje i upotrebu
          vaših ličnih podataka, u skladu sa ovom Politikom privatnosti.
        </p>
        <h2>TUMAČENJE I DEFINICIJE</h2>
        <h2>TUMAČENJE</h2>
        <p>
          Riječi čije je početno slovo napisano velikim slovom imaju definisana
          značenja pod sljedećim uslovima. Sljedeće definicije će imati isto
          značenje bez obzira pojavljuju li se u jednini ili u množini, u muškom
          ili ženskom rodu.
        </p>
        <h2>DEFINICIJE</h2>
        <p>Za potrebe ove Politike privatnosti:</p>
        <ul>
          <li>
            <strong>Aplikacija</strong> je softverski program pod imenom KONFIDI
            mobilni novčanik, razvijen od strane Kompanije, a preuzet od strane
            vas.{" "}
          </li>
          <li>
            <strong>Kompanija</strong> (u ovom tekstu nazivana ili “kompanija”,
            “mi”, “mi” ili “naša”) je KONFIDI d.o.o, PIB 03391493, sa adresom u
            ul. Rusa Radulovića br. 43, 81000 Podgorica, Crna Gora.
          </li>
          <li>
            Za potrebe GDPR-a, kompanija je <strong>kontrolor podataka.</strong>
          </li>
          <li>
            <strong>Država</strong> je Crna Gora.
          </li>
          <li>
            <strong>Kontrolor</strong> podataka, za potrebe GDPR-a (Opšta uredba
            o zaštiti ličnih podataka), je Kompanija kao pravno lice koje samo
            ili zajedno s drugima, određuje svrhu i način obrade Ličnih
            podataka.
          </li>
          <li>
            <strong>Uređaj</strong> je bilo koji uređaj koji može pristupiti
            Usluzi, poput računara, mobilnog telefona ili digitalnog tableta.
          </li>
          <li>
            <strong>Lični podaci</strong> su sve informacije koje se odnose na
            identifikovanog pojedinca ili pojedinca koji se može identifikovati.
          </li>
        </ul>
        <p>
          Za potrebe GDPR-a, lični podaci označavaju sve informacije koje se
          odnose na vas, kao što su ime, identifikacioni broj, podaci o
          lokaciji, online identifikator ili na jedan ili više faktora
          specifičnih za fizički, fiziološki, genetski, mentalni, ekonomski,
          kulturni ili društveni identitet.
        </p>
        <p>Usluga se odnosi na Aplikaciju.</p>
        <p>
          Pružalac Usluge je svako fizičko ili pravno lice koje obrađuje podatke
          u ime Kompanije. Odnosi se na eksterno angažovane kompanije ili
          pojedince zapošljene u Kompaniji radi omogućavanja Usluge, pružanja
          Usluge u ime Kompanije, obavljanja aktivnosti u vezi sa Uslugom ili
          pomaganja Kompaniji u analizi načina na koji se Usluga koristi. U
          svrhu GDPR-a, Pružalac Usluge se smatra obrađivačem podataka.
        </p>
        <p>
          Usluga dru&scaron;tvenih medija trećih strana odnosi se na bilo koju
          web stranicu ili bilo koju stranicu određene dru&scaron;tvene mreže
          putem koje se Korisnik može prijaviti ili kreirati nalog za
          kori&scaron;ćenje Usluge.
        </p>
        <p>
          Podaci o upotrebi su podaci prikupljeni automatski, bilo da su
          generisani kori&scaron;ćenjem Usluge ili prikupljeni iz same
          infrastrukture Usluge (na primjer, trajanje posjeta stranici).
        </p>
        <p>
          &ldquo;Vi&rdquo; se odnosi na pojedinca koji pristupa ili koristi
          Uslugu ili na kompaniju, ili drugo pravno lice u ime kojeg to lice
          pristupa ili koristi Uslugu, prema potrebi.
        </p>
        <p>
          Prema GDPR -u (Op&scaron;ta uredba o za&scaron;titi ličnih podataka),
          &ldquo;Vi&rdquo; se može odnositi na subjekt podataka ili na
          korisnika, kao pojedinca koji koristi Uslugu.
        </p>
        <h2>PRIKUPLJANJE I KORIŠĆENJE VAŠIH LIČNIH PODATAKA</h2>
        <h2>VRSTE PRIKUPLJENIH PODATAKA</h2>
        <h2> LIČNI PODACI</h2>
        <p>
          Konfidi d.o.o. Ne prikuplja vaše lične identifikacione podatke poput
          imena ili kontakt podataka, već samo meta podatke koji se tiču
          upotrebe aplikacije ili vaših karakteristika (pol, starosna dob i sl).
          Svi podaci koje prikupimo su depersonalizovani i ne otkrivaju vaš
          identitet.
        </p>
        <h2>PODACI O UPOTREBI</h2>
        <p>
          Podaci o upotrebi prikupljaju se automatski, prilikom
          kori&scaron;ćenja usluge. Podaci o upotrebi mogu uključivati
          informacije poput adrese internet protokola va&scaron;eg uređaja (npr.
          IP adresa), tipa pretraživača, verzije pretraživača, stranice
          na&scaron;e Usluge koju posjećujete, vrijeme i datum va&scaron;e
          posjete, vrijeme provedeno na određenoj stranici, jedinstvene
          identifikatore uređaja i druge dijagnostičke podatke.
        </p>
        <p>
          Kada pristupite Usluzi putem mobilnog telefona, možemo automatski
          prikupljati određene informacije, uključujući, ali ne ograničavajući
          se na vrstu mobilnog telefona koji koristite, jedinstveni ID
          va&scaron;eg mobilnog uređaja, IP adresu va&scaron;eg mobilnog
          telefona, operativni sistem va&scaron;eg mobilnog telefona, vrstu
          pretraživača za mobilni internet koji koristite, jedinstvene
          identifikatore uređaja i druge dijagnostičke podatke.
        </p>
        <p>
          Takođe možemo prikupljati podatke koje va&scaron; pretraživač
          &scaron;alje kad god posjetite na&scaron;u Aplikaciju ili kada
          Aplikaciji pristupate putem mobilnog telefona.
        </p>
        <h2>PODACI PRIKUPLJENI TOKOM KORIŠĆENJA APLIKACIJE</h2>
        <p>
          Dok koristite našu Aplikaciju, u cilju omogućavanja funkcionalnosti
          naše Aplikacije, uz vaše prethodno dopuštenje, možemo zahtjevati:
        </p>
        <ul>
          <li>
            Pristup kameri i drugim informacija iz fotoaparata o fotografijama
            koje su napravljene iz naše aplikacije.
          </li>
        </ul>
        <p>
          Ove podatke koristimo za pružanje funkcionalnosti naše Usluge, kao i
          za poboljšanje i prilagođavanje naše Usluge. Podaci se mogu učitati na
          servere i/ili server Kompanije ili njenih dobavljača usluga, ili se
          jednostavno mogu pohraniti na vašim uređajima.
        </p>
        <p>
          Pristup ovim podacima možete omogućiti ili onemogućiti u bilo kom
          trenutku, kroz podešavanje postavki uređaja.
        </p>
        <h2>KORIŠĆENJE VAŠIH PODATAKA</h2>
        <p>Kompanija može koristiti va&scaron;e podatke u sljedeće svrhe:</p>
        <ul>
          <li>
            Za pružanje i održavanje na&scaron;e Usluge, uključujući i praćenje
            kori&scaron;ćenja na&scaron;e Usluge.
          </li>
          <li>
            Da vas kontaktiramo: Da vas kontaktiramo putem push notifikacija
            kroz Aplikaciju, u vezi sa ažuriranjem ili informacijama povezanih s
            funkcionalnostima, uključujući sigurnosna ažuriranja, kada je
            potrebna njihova implementacija.
          </li>
          <li>
            Da bismo vam dostavili vijesti, posebne ponude i op&scaron;te
            informacije o drugim uslugama koje nudimo, a koje su slične onima
            koje ste već koristili ili o kojima ste se raspitivali, osim ako se
            ste se odlučili da ne primate takve informacije.
          </li>
          <li>
            Za upravljanje va&scaron;im zahtjevima: Za prisustvovanje i
            upravljanje va&scaron;im zahtjevima prema nama.
          </li>
          <li>
            Za poslovne prenose: Va&scaron;e podatke možemo koristiti za
            procjenu ili sprovođenje spajanja, prodaje, restrukturiranja,
            reorganizacije, raspu&scaron;tanja ili druge prodaje ili prenos neke
            ili cijele na&scaron;e imovine, nezavisno od toga da li je riječ o
            stečajnom postupku, likvidaciji ili sličnom postupku, u kojem lični
            podaci koje posjedujemo o na&scaron;im korisnicima usluga, spadaju u
            prenesenu imovinu .
          </li>
          <li>
            U druge svrhe: Va&scaron;e podatke možemo koristiti u druge svrhe,
            npr. za analizu podataka, identifikovanje trendova upotrebe,
            određivanje efikasnosti na&scaron;ih promotivnih kampanja te za
            ocjenjivanje i pobolj&scaron;anje na&scaron;e Usluge, proizvoda,
            drugih usluga, marketin&scaron;kih aktivnosti i va&scaron;eg
            iskustva.
          </li>
        </ul>
        <p>Va&scaron;e podatke možemo podijeliti u sljedećim situacijama:</p>
        <ul>
          <li>
            S pružaocima Usluge: Va&scaron;e podatke možemo podijeliti s
            pružaocima Usluge za praćenje i analizu kori&scaron;ćenja
            na&scaron;e usluge.
          </li>
          <li>
            S poslovnim partnerima: Va&scaron;e podatke možemo podijeliti s
            na&scaron;im poslovnim partnerima,&nbsp; kako bi vam ponudili
            određene proizvode, usluge ili promocije.
          </li>
        </ul>
        <h2>ČUVANJE VAŠIH LIČNIH PODATAKA</h2>
        <p>
          Kompanija će zadržati vaše lične podatke samo onoliko koliko je
          potrebno u svrhe navedene u ovoj Politici privatnosti. Zadržaćemo i
          koristiti Vaše lične podatke u mjeri u kojoj je to potrebno za
          usklađivanje s našim zakonskim obavezama (na primjer, ako se od nas
          traži da zadržimo vaše podatke u skladu s važećim zakonima),
          rješavanje sporova i implementiranje naših pravnih sporazuma i
          politika.
        </p>
        <p>
          Kompanija će takođe čuvati podatke o upotrebi za potrebe interne
          analize. Podaci o upotrebi se generalno čuvaju kraći vremenski period,
          osim kada se ti podaci koriste se za jačanje bezbjednosti ili
          poboljšanje funkcionalnosti naše Usluge ili smo u zakonskoj obavezi da
          čuvamo ove podatke određeni duži vremenski period.
        </p>
        <h2>PRENOS VAŠIH LIČNIH PODATAKA</h2>
        <p>
          Va&scaron;i podaci, uključujući lične podatke, obrađuju se u
          operativnim prostorijama Kompanije i na bilo kojim drugim mjestima
          gdje se nalaze strane uključene u njihovo procesuiranje.
        </p>
        <p>
          To znači da se ove informacije mogu prenijeti na &ndash; i održavati
          se na &ndash; računarima koji se nalaze van va&scaron;e države,
          pokrajine, zemlje ili ili druge državne organizacije u kojoj se zakoni
          o za&scaron;titi podataka mogu razlikovati od onih iz va&scaron;e
          države.
        </p>
        <p>
          Va&scaron; pristanak na ovu Politiku privatnosti, nakon čega slijedi
          va&scaron;e podno&scaron;enje takvih podataka, predstavlja i
          va&scaron; pristanak na taj prenos.
        </p>
        <p>
          Kompanija će preduzeti sve potrebne korake kako bi osigurala da
          va&scaron;i podaci budu tretirani sigurno i u skladu s ovom Politikom
          privatnosti i neće biti prenosa va&scaron;ih ličnih podataka
          organizaciji ili zemlji, osim ako u njima ne postoje odgovarajuće
          kontrole, uključujući obezbjeđivanje sigurnosti va&scaron;ih podataka
          i druge lične podatke.
        </p>
        <h2>OTKRIVANJE VAŠIH LIČNIH PODATAKA</h2>
        <h2>POSLOVNE TRANSAKCIJE</h2>
        <p>
          Ako je Kompanija uključena u spajanje, akviziciju ili prodaju imovine,
          vaši lični podaci se mogu prenositi. Bićete obaviješteni prije prenosa
          vaših ličnih podataka i primjene drugačije Politike privatnosti.
        </p>
        <h2>SPROVOĐENJE ZAKONA</h2>
        <p>
          Pod određenim okolnostima, od Kompanije se može zahtijevati da otkrije
          vaše lične podatke, ako se to zahtijeva zakonom ili kao odgovor na
          validne zahtjeve javne vlasti (npr. suda ili vladine agencije).
        </p>
        <h2>OSTALI ZAKONSKI USLOVI</h2>
        <p>
          Kompanija može otkriti va&scaron;e lične podatke, ukoliko je uvjerena
          da je ta akcija neophodna da bi se:
        </p>
        <ul>
          <li>Pridržavala zakonskih obaveza</li>
          <li>&Scaron;titila i branila prava ili imovinu Kompanije</li>
          <li>
            Spriječila ili istražila moguće nepravilnosti u vezi sa Uslugom
          </li>
          <li>
            Za&scaron;titila ličnu sigurnost korisnika Usluge ili javnosti
          </li>
          <li>Za&scaron;titila se od pravne odgovornosti</li>
        </ul>
        <h2>SIGURNOST VAŠIH LIČNIH PODATAKA</h2>
        <p>
          Sigurnost vaših ličnih podataka nam je važna, ali zapamtite da nijedan
          način prenosa putem Interneta, odnosno način elektronskog skladištenja
          nije 100% siguran. Iako nastojimo koristiti komercijalno prihvatljiva
          sredstva za zaštitu vaših ličnih podataka, ne možemo garantovati
          njihovu apsolutnu sigurnost.
        </p>
        <h2>DETALJNE INFORMACIJE O OBRADI VAŠIH LIČNIH PODATAKA</h2>
        <p>
          Pružaoci usluga koje koristimo mogu imati pristup vašim ličnim
          podacima. Ovi dobavljači prikupljaju, skladište, koriste, obrađuju i
          prenose podatke o vašim aktivnostima u okviru naše Usluge i u skladu s
          njihovom politikom privatnosti.
        </p>
        <h2>ANALITIKA</h2>
        <p>
          Možemo koristiti pružaoce usluga trećih strana za praćenje i analizu
          korišćenja naše Usluge.
        </p>
        <h2>UPOTREBA, PERFORMANSE I OSTALO</h2>
        <p>
          Možemo koristiti eksterne pružaoce servisa kako bismo osigurali
          poboljšanje naše Usluge.
        </p>
        <h2>AMPLITUDE</h2>
        <p>
          Amplitude je softver za analitiku korišćenja aplikacije koji pruža
          informacije o konverziji, funkcionalnostima i opcijama koje korisnici
          najčešće koriste. Podaci koje prikuplja Amplitude čuvaju se u skladu
          sa njihovim Pravilima privatnosti:{" "}
          <a href="https://amplitude.com/privacy">
            https://amplitude.com/privacy
          </a>
        </p>
        <h2>EXPO</h2>
        <p>
          EXPO je softver za ažuriranje i izradu aplikacije. Podaci koje
          prikuplja EXPO čuvaju se u skladu sa njihovim Pravilima privatnosti:
          <a href="https://expo.dev/privacy">https://expo.dev/privacy</a>
        </p>
        <h2>GOOGLE PLAY</h2>
        <p>
          Google Play je android platforma za preuzimanje i korišćenje mobilnih
          aplikacija. Podaci koje prikuplja Google Play čuvaju se u skladu sa
          njihovim Pravilima privatnosti:
          <a href="https://www.google.com/policies/privacy/">
            https://www.google.com/policies/privacy/
          </a>
        </p>
        <h2>SENTRY.IO</h2>
        <p>
          SENTRY.IO je platforma za prikupljanje podataka o bug-ovima i greškama
          u aplikaciji. Podaci koje prikuplja Google Play čuvaju se u skladu sa
          njihovim Pravilima privatnosti:{" "}
          <a href="https://sentry.io/privacy/">https://sentry.io/privacy/</a>
        </p>
        <h2>GDPR PRIVATNOST</h2>
        <p>PRAVNA OSNOVA ZA OBRADU LIČNIH PODATAKA PREMA GDPR-U</p>
        <p>Lične podatke možemo obrađivati pod sljedećim uslovima:</p>
        <ul>
          <li>
            Pristanak: Pristali ste na obradu va&scaron;ih&nbsp; ličnih podataka
            u određene svrhe.
          </li>
          <li>
            Izvr&scaron;enje ugovora: Davanje ličnih podataka je neophodno za
            izvr&scaron;avanje ugovora sa vama i/ili za bilo koje predugovorne
            obaveze.
          </li>
          <li>
            Pravne obaveze: Obrada ličnih podataka neophodna je za usklađenost
            sa zakonskom obavezom kojoj Kompanija podliježe.
          </li>
          <li>
            Vitalni interesi: Obrada ličnih podataka neophodna je radi
            za&scaron;tite va&scaron;ih vitalnih interesa ili vitalnih interesa
            neke druge osobe.
          </li>
          <li>
            Javni interesi: Obrada ličnih podataka povezana je sa zadatkom koji
            je sproveden u javnom interesu ili prilikom vr&scaron;enja službenih
            dužnosti ili u okviru ovla&scaron;ćenja koja su data Kompaniji.
          </li>
          <li>
            Legitimni interesi: Obrada ličnih podataka neophodna je u svrhu
            ispunjavanja zakonitih interesa koje sprovodi Kompanija.
          </li>
        </ul>
        <p>
          U svakom slučaju, Kompanija će rado pomoći u razja&scaron;njavanju
          posebne pravne osnove koja se odnosi na obradu, a posebno na to da li
          je potreba za obradom ličnih podataka zakonskog ili ugovornog
          karaktera, ili je nastala zbog potrebe za sklapanjem ugovora.
        </p>
        <h2>VAŠA PRAVA PREMA GDPR -U</h2>
        <p>
          Kompanija se obavezuje po&scaron;tovati povjerljivost va&scaron;ih
          ličnih podataka i garantuje da možete ostvariti svoja prava. Prema
          ovoj politici privatnosti, a prema zakonu, ako se nalazite u EU, imate
          pravo da:
        </p>
        <ul>
          <li>
            Zatražite pristup va&scaron;im ličnim podacima. Pravo pristupa,
            ažuriranja ili brisanja podataka koje imamo o vama. Kad god je to
            moguće, možete pristupiti, ažurirati ili zatražiti brisanje
            va&scaron;ih ličnih podataka direktno u okviru postavki va&scaron;eg
            naloga. Ako nijeste u mogućnosti izvr&scaron;iti ove radnje sami,
            kontaktirajte nas kako bismo vam pomogli. Ovo vam takođe omogućava
            da primite kopiju ličnih podataka koje imamo o vama.
          </li>
          <li>
            Zatražite ispravku ličnih podataka koje imamo o vama. Vi imate pravo
            na ispravljanje bilo kojih nepotpunih ili netačnih podataka koje mi
            imamo.
          </li>
          <li>
            Uložite prigovor na obradu va&scaron;ih ličnih podataka. Ovo pravo
            postoji tamo gdje smo se mi oslanjali na zakonski okvir kao pravnu
            osnovu za na&scaron;u obradu, a postoji ne&scaron;to u va&scaron;oj
            konkretnoj situaciji, zbog čega se želite usprotiviti na&scaron;oj
            obradi va&scaron;ih ličnih podataka, po ovom osnovu. Vi takođe imate
            pravo prigovora na obradu va&scaron;ih ličnih podataka u svrhe
            direktnog marketinga.
          </li>
          <li>
            Zatražite brisanje va&scaron;ih ličnih podataka. Imate pravo
            zatražiti od nas da izbri&scaron;emo ili uklonimo va&scaron;e lične
            podatke, kada nemamo valjan razlog za nastavak njihove obrade.
          </li>
          <li>
            Zatražite prenos va&scaron;ih ličnih podataka. Mi ćemo dostaviti
            vama ili trećoj strani koju ste odabrali, va&scaron;e lične podatke
            u struktuiranom elektronskom obliku, koji se obično koristi. Imajte
            na umu da se ovo pravo odnosi samo na automatizovane informacije za
            koje ste prvobitno dali pristanak za njihovo kori&scaron;ćenje, ili
            na podatke koji su kori&scaron;ćeni za sklapanje ugovora sa vama.
          </li>
          <li>
            Povučete pristanak. Imate pravo povući pristanak za
            kori&scaron;ćenje va&scaron;ih ličnih podataka. Ako povučete svoj
            pristanak, možda nećemo biti u mogućnosti da vam omogućimo pristup
            određenim specifičnim ili svim funkcijama Na&scaron;e Usluge.
          </li>
        </ul>
        <h2>OSTVARIVANJE VAŠIH GDPR PRAVA NA ZAŠTITU PODATAKA</h2>
        <p>
          Možete iskoristiti svoja prava pristupa, ispravljanja, otkazivanja i
          prigovaranja, kontaktirajući nas. Imajte na umu da ćemo od vas možda
          tražiti da potvrdite svoj identitet prije nego što odgovorimo na takve
          zahtjeve. Ako podnesete zahtjev, mi ćemo se potruditi da vam
          odgovorimo što je prije moguće.
        </p>
        <p>
          Imate pravo uložiti žalbu nadležnom tijelu za zaštitu podataka u vezi
          sa našem prikupljanje i korišćenjem vaših ličnih podataka. Za više
          informacija, ako ste u Evropskom ekonomskom prostoru (EEA),
          kontaktirajte vašu lokalnu ustanovu za zaštitu ličnih podataka u
          EEA-u.
        </p>
        <h2>PRIVATNOST DJECE</h2>
        <p>
          Naš servis se ne obraća nikome mlađem od 13 godina. Mi, svjesno, ne
          prikupljamo lične podatke od bilo koga mlađeg od 13 godina. Ako ste
          roditelj ili staratelj i svjesni ste da je vaše dijete dalo nama svoje
          lične podatke, kontaktirajte nas. Ako postanemo svjesni da smo
          prikupili lične podatke od bilo koga mlađeg od 13 godina bez provjere
          roditeljskog pristanka, preduzećemo korake da uklonimo te podatke sa
          našeg servera.
        </p>
        <p>
          Ako se moramo osloniti na pristanak kao pravnu osnovu za obradu vaših
          podataka i vaša država zahtijeva pristanak roditelja, možemo
          zahtijevati i pristanak vašeg roditelja prije nego što prikupimo i
          koristimo te podatke.
        </p>
        <h2>LINKOVI NA DRUGE WEB STRANICE</h2>
        <p>
          Naša usluga može sadržavati linkove koji vode do drugih web stranica,
          kojima mi ne upravljamo. Ako kliknete na link treće strane, bićete
          preusmjereni na web stranicu te treće strane. Savjetujemo vam da
          pregledate Pravila privatnosti svake web stranice koju posjećujete.
        </p>
        <p>
          Nemamo kontrolu nad i ne preuzimamo odgovornost za sadržaj, politiku
          ili praksu privatnosti bilo koje web stranice ili usluge trećih
          strana.
        </p>
        <h2>IZMJENE OVE POLITIKE PRIVATNOSTI</h2>
        <p>
          S vremena na vrijeme, možemo ažurirati našu Politiku privatnosti.
          Obavijestićemo vas o bilo kojoj promjeni objavljivanjem nove Politike
          privatnosti na ovoj stranici. Prethodno ćemo vas obavijestiti putem
          e-pošte i/ili istaknutog obaveštenja u okviru naše Usluge da promjena
          stupa na snagu i ažuriraćemo datum kod stavke “Zadnje ažuriranje” na
          početku ove Politike privatnosti.
        </p>
        <p>
          Savjetujemo vam da povremeno pregledate ovu Politiku privatnosti radi
          izmjena. Izmjene ove Politike privatnosti stupaju na snagu datumom
          objavljivanja na ovoj stranici.
        </p>
        <h2>KONTAKTIRAJTE NAS</h2>
        <p>
          Ako imate pitanja o ovoj Politici privatnosti, možete nas kontaktirati
          e-poštom: info@konfidi.me
        </p>
        <p>
          Konfidi mobilni novčanik je proizvod Konfidi d.o.o. ©2022 Sva prava
          zadržana.
        </p>
      </PrivacyStyled>
    </Container>
  );
};
export default Privacy;
